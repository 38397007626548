import { EmbeddingService, EmbedMessageNavigate } from '@accredible-frontend-v2/embedding';
import { AccountsRedirectionKey } from '@accredible-frontend-v2/services/accounts-redirection';
import { AccredibleAPIMockData, AccredibleApiService } from '@accredible-frontend-v2/services/api';
import { HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DashboardApiService extends AccredibleApiService {
  embedding = inject(EmbeddingService);

  protected _handleError(
    res: HttpErrorResponse,
    propagate404 = false,
    mockData?: AccredibleAPIMockData,
  ): Observable<any> {
    switch (res.status) {
      case 307:
        // If API returns 307 it means issuer needs to configure MFA
        // TODO V1(Fred): Change to router navigation once we get rid of V1
        this.embedding.postMessage<EmbedMessageNavigate>({
          type: 'navigate',
          payload: {
            route: 'issuer.mfa.mfa-setup',
          },
        });
        return;

      case 400:
        return super._handleError(res, propagate404, mockData);

      case 401:
        // Session expired or token invalid
        this.cookies.delete(AccountsRedirectionKey.SESSION_TOKEN_COOKIE);

        this.embedding.postMessage({
          type: '401',
        });
        break;
      case 403:
      case 409:
      case 422:
      case 425:
      case 500:
      case 503:
        return super._handleError(res);

      case 404:
        return super._handleError(res, propagate404, mockData);

      case 0:
        // In case network goes down
        return super._handleError(res);
    }

    return throwError(() => new Error(res.message));
  }
}
